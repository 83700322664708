<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div>
          <vue-excel-xlsx
            class="btn btn-primary btn-sm"
            :data="excelData"
            :columns="columns"
            :file-name="'Newsletter'"
            :file-type="'xlsx'"
            :sheet-name="'Newsletter'"
          >
            {{ $t("tables.export excel") }}
          </vue-excel-xlsx>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="emails"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.date") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.subscriber_email }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
  <!-- Newsletter Modal -->
  <!-- <div
    v-if="newsletterModal"
    class="modal fade show d-block"
    id="newsletterModal"
    tabindex="-1"
    aria-labelledby="newsletterModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="newsletterModalLabel">
            {{ $t("form.newsletter_form") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="newsletterModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row bg-danger py-3 mb-3" v-if="validationErrors.length">
              <div
                class="col-md-6"
                v-for="(error, index) in validationErrors"
                :key="index"
              >
                <span class="text-white mr-2 error-handling">{{ error }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{
                  $t("form.message")
                }}</label>
                <textarea
                  class="form-control"
                  v-model="newsletterData.message"
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="newsletterModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button type="button" class="btn btn-success" @click="sendBulk()">
            {{ $t("form.send") }}
          </button>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "emails-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      // Modals
      newsletterModal: false,
      newsletterData: {},
      emails: "",
      total_rows: 1,
      page: 1,
      total_pages: "",
      columns: [
        {
          label: "email",
          field: "subscriber_email",
        },
      ],
      excelData: [],
    };
  },
  created() {
    this.moment = moment;
    this.getEmails("admin/administration/subscribers/index?page=1");
    this.getAllEmails("admin/administration/subscribers/indexPaginate");
  },
  watch: {
    page: function () {
      this.getEmails(
        "admin/administration/subscribers/index?page=" + this.page
      );
    },
  },
  methods: {
    getEmails: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.emails = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    getAllEmails: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.excelData = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // validation: function () {
    //   this.validationErrors = [];
    //   if (this.newsletterData.message) {
    //     return true;
    //   } else {
    //     if (!this.newsletterData.message) {
    //       this.validationErrors.push(
    //         `${this.$t("validation.message required")}`
    //       );
    //     }
    //     return false;
    //   }
    // },
    // Send Bulk Emails
    // sendBulk: function () {
    //   if (this.validation() == false) {
    //     this.$swal({
    //       position: "center",
    //       icon: "error",
    //       title: `${this.$t("validation.faild")}`,
    //       showConfirmButton: false,
    //       timer: 1200,
    //     });
    //     return false;
    //   }
    //   this.loading = true;
    //   axios
    //     .post("admin/administration/subscribers/sendBulk", {
    //       value: this.newsletterData.message,
    //     })
    //     .then((response) => {
    //       if (response.data.error) {
    //         this.$swal({
    //           position: "center",
    //           icon: "error",
    //           title: `${this.$t("validation.faild")}`,
    //         });
    //       } else {
    //         if (response.data.status == 0) {
    //           this.$swal({
    //             position: "center",
    //             icon: "error",
    //             title: response.data.message,
    //             showConfirmButton: false,
    //           });
    //         } else {
    //           this.newsletterModal = false;
    //           this.$swal({
    //             position: "center",
    //             icon: "success",
    //             title: `${this.$t("validation.success")}`,
    //             text: response.data.message,
    //             showConfirmButton: false,
    //             timer: 1200,
    //           });
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       if (!window.navigator.onLine) {
    //         this.$swal({
    //           position: "center",
    //           icon: "error",
    //           title: `${this.$t("validation.faild")}`,
    //           text: `${this.$t("validation.check network")}`,
    //         });
    //       }
    //     })
    //     .finally(() => (this.loading = false));
    // },
  },
};
</script>
